import { createStore } from "vuex";

export default createStore({
  state: {
    loading: false,
    mypostit: [],
    page: 1,
    afficher: 40,
    total: 0,
    requete: "",
    curentPost: null,
  },
  getters: {
    paginatepost: (state) => {
      const star = (state.page - 1) * state.afficher;
      const end = star + state.afficher;
      console.log("start" + star + "end" + end);
      return state.mypostit.slice(star, end);
    },
    totalPages: (state) => Math.ceil(state.mypostit.length / state.afficher),
    curentPost: (state) => state.curentPost,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_POSTIT(state, payload) {
      console.log("payload" + payload);
      state.mypostit = payload.notes;
      state.total = payload.total;
    },
    SET_PAGE(state, payload) {
      console.log("payload page" + payload);
      state.page = payload;
    },
    SET_REQUETE(state, payload) {
      state.requete = payload;
    },
    ADD_POSTIT(state, payload) {
      state.mypostit.push(payload);
    },
    SET_C_POST(state, payload) {
      state.curentPost = payload;
    },
    UPDATE_POST(state, updatePost) {
      state.curentPost = updatePost;
    },
  },
  actions: {
    async getAllData({ commit }) {
      commit("SET_LOADING", true);
      const response = await fetch(`https://post-it.epi-bluelock.bj/notes`);
      const donnee = await response.json();
      commit("SET_POSTIT", { notes: donnee.notes, total: donnee.notes.length });
      commit("SET_LOADING", false);
    },
    async refreshlist({ dispatch }) {
      await dispatch("getAllData");
    },
    setPage({ commit }, page) {
      commit("SET_PAGE", page);
    },
    async addPost({ commit }, postit) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(postit);
      const response = await fetch("https://post-it.epi-bluelock.bj/notes", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });
      if (response.ok) {
        const data = await response.json();
        commit("ADD_POSTIT", data);
      } else {
        commit("SET_REQUETE", "Post it No save");
      }
    },
    async getOneNote({ commit }, id) {
      const response = await fetch(
        "https://post-it.epi-bluelock.bj/notes/" + id
      );
      const post = await response.json();
      commit("SET_C_POST", post);
    },
    async updatePost({ commit }, { id, post }) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const contdent = post.content.toString();
      var raw = JSON.stringify({
        title: post.title,
        content: [contdent],
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = fetch(
        `https://post-it.epi-bluelock.bj/notes/${id}`,
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        commit("ADD_POSTIT", data);
        commit("UPDATE_POST", post);
      } else {
        commit("SET_REQUETE", "Post it No save");
      }
    },
    async deletePost({ commit }, id) {
      var requestOptions = {
        method: "DELETE",
        redirect: "follow",
      };
      const response = await fetch(
        "https://post-it.epi-bluelock.bj/notes/" + id,
        requestOptions
      );
      if (response.ok) {
        // const data = await response.json();
        return true;
      } else {
        commit("SET_REQUETE", "Post it No save");
      }
    },
  },
  modules: {},
});
