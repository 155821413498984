<template>
  <v-container-fluid class="">
    <v-row>
      <v-col v-if="loading">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col
        v-for="(todo, i) in paginatepost"
        :key="i"
        cols="12"
        md="3"
        xs="4"
        sm="6"
      >
        <v-card
          :class="cardColor(i)"
          class="mx-auto ma-2 pa-2 backcolor"
          prepend-icon=""
          subtitle=""
          width="300"
          overflow="hidden"
        >
          <template v-slot:title>
            <span class="font-weight-black">{{ todo.title }}</span>
          </template>
          <v-card-text
            v-for="(content, i) in todo.content"
            :key="i"
            class="pt-4 mataille"
          >
            {{ content }}
          </v-card-text>
          <v-row class="pt-4" no-gutters justify="end">
            <v-btn color="yellow" @click="show(todo._id)" icon="mdi-mouse">
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container-fluid>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="totalPages"
      :total-visible="7"
      @input="changePage"
    ></v-pagination>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "HomeView",
  computed: {
    ...mapState(["loading", "page", "afficher", "total"]),
    ...mapGetters(["paginatepost", "totalPages"]),
  },
  async mounted() {
    await this.refreshlist();
  },
  methods: {
    ...mapActions(["getAllData", "setPage", "refreshlist"]),
    changePage(page) {
      console.log("page set" + page);
      this.setPage(page);
    },
    async show(id) {
      this.$router.push({ name: "show", params: { id: id } });
    },
    cardColor(index) {
      const bgcolors = ["yellow", "blue", "green"];
      const colors = ["black", "white", "white"];
      const bgcolorsI = index % bgcolors.length;
      const colorI = index % colors.length;
      return {
        [`bg-${bgcolors[bgcolorsI]}`]: true,
        [`text-${colors[colorI]}`]: true,
      };
    },
  },
};
</script>
<style>
.mataille {
  height: 100px;
  overflow: hidden;
}
/* .backcolor:first-child {
  background-color: red;
  :style="{ 'background-color': bgcolors[i], color: colors[i] }"
} */
.bg-yellow {
  background-color: yellow;
}
.bg-blue {
  background-color: blue;
}
.bg-green {
  background-color: green;
}
.text-black {
  color: black;
}
.text-white {
  color: white;
}
</style>
<!-- <template>
  <div>B</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script> -->
<!-- <template>
  <v-container-fluid class="">
    <v-row>
      <v-col v-if="loading">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col
        v-for="(todo, i) in mypostit"
        :key="i"
        cols="12"
        md="3"
        xs="4"
        sm="6"
      >
        <v-card
          :class="cardColor(i)"
          class="mx-auto ma-2 pa-2 backcolor"
          prepend-icon=""
          subtitle=""
          width="300"
          overflow="hidden"
        >
          <template v-slot:title>
            <span class="font-weight-black">{{ todo.title }}</span>
          </template>
          <v-card-text
            v-for="(content, i) in todo.content"
            :key="i"
            class="pt-4 mataille"
          >
            {{ content }}
          </v-card-text>
          <v-row class="pt-4" no-gutters justify="end">
            <v-btn color="yellow" @click="show(todo._id)" icon="mdi-mouse">
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container-fluid>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="Math.ceil(total / afficher)"
      :total-visible="7"
      @input="affichepage"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data: () => ({
    loading: false,
    mypostit: [],
    bgcolors: ["yellow", "blue", "green"],
    colors: ["black", "white", "white"],
    page: 1,
    afficher: 10,
    total: 0,
  }),
  mounted: async function () {
    this.getAllData();
    // this.loading = true;
    // const response = await fetch("https://post-it.epi-bluelock.bj/notes");
    // const donnee = await response.json();
    // this.mypostit = donnee.notes;
    // this.total = donnee.notes.length;
    // this.loading = false;
  },
  methods: {
    async getAllData() {
      this.loading = true;
      const response = await fetch(`https://post-it.epi-bluelock.bj/notes`);
      // https://post-it.epi-bluelock.bj/notes?page=${this.page}&limit=${this.afficher}
      const donnee = await response.json();
      this.mypostit = donnee.notes;
      this.total = donnee.notes.length;
      this.loading = false;
    },
    async refreshlist() {
      this.getAllData();
    },
    async show(id) {
      this.$router.push({ name: "show", params: { id: id } });
    },
    affichepage(page) {
      this.page = page;
      this.getAllData();
    },
    cardColor(index) {
      const bgcolorsI = index % this.bgcolors.length;
      const colorI = index % this.colors.length;
      return {
        backcolor: true,
        [`bg-${this.bgcolors[bgcolorsI]}`]: true,
        [`text-${this.colors[colorI]}`]: true,
      };
    },
  },
};
</script>
<style>
.mataille {
  height: 100px;
  overflow: hidden;
}
/* .backcolor:first-child {
  background-color: red;
  :style="{ 'background-color': bgcolors[i], color: colors[i] }"
} */
.bg-yellow {
  background-color: yellow;
}
.bg-blue {
  background-color: blue;
}
.bg-green {
  background-color: green;
}
.text-black {
  color: black;
}
.text-white {
  color: white;
}
</style>
<template>
  <div>B</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script> -->
