<template>
  <v-app>
    <v-toolbar>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <RouterLink class="chocote" to="/"> Post It </RouterLink>
      <v-spacer></v-spacer>
      <v-btn class="bg-yellow" to="/add" prepend-icon="mdi-post">
        Add Post-it
      </v-btn>
    </v-toolbar>
    <v-main>
      <RouterView />
    </v-main>
  </v-app>
</template>
<style>
.chocote {
  text-decoration: none;
  color: rgb(11, 55, 248);
}
</style>
<!-- <template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view />
</template> -->
