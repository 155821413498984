<template>
  <v-container-fluid>
    <v-col cols="2">
      <v-btn
        color="success"
        :style="{ width: '100%', borderRadius: 'unset' }"
        @click="retour"
        icon="mdi-undo"
      ></v-btn>
    </v-col>
    <v-row class="d-flex justify-center">
      <v-col cols="9" md="4" class="my-5 pa-4">
        <v-card class="bgcard">
          <template v-slot:title>
            <span :style="{ fontWeight: 'bold', color: 'black' }">{{
              title
            }}</span>
          </template>

          <template v-slot:subtitle> </template>

          <template v-for="(content, i) in content" :key="i">
            {{ content }}
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container-fluid>
  <v-row>
    <v-col>
      <v-btn
        :style="{ width: '50%', borderRadius: 'unset' }"
        color="blue"
        @click="edit()"
        icon="$edit"
      ></v-btn>
    </v-col>
    <v-col>
      <v-btn
        color="red"
        :style="{ width: '50%', borderRadius: 'unset' }"
        @click="delet()"
        icon="$delete"
      ></v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    requete: "",
    title: "",
    titleRules: [(v) => !!v || "Title is required"],
    content: "",
    contentRules: [(v) => !!v || "Content is required"],
  }),
  mounted: async function () {
    const id = this.$route.params.id;
    const response = await fetch("https://post-it.epi-bluelock.bj/notes/" + id);
    this.requete = await response.json();
    this.title = this.requete.title;
    this.content = this.requete.content;
  },
  methods: {
    ...mapActions(["deletePost", "refreshlist"]),
    // async update() {
    //   const id = this.$route.params.id;
    //   var myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");

    //   var raw = JSON.stringify({
    //     title: this.title,
    //     content: [this.content],
    //   });

    //   var requestOptions = {
    //     method: "PUT",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow",
    //   };

    //   fetch("https://post-it.epi-bluelock.bj/notes/" + id, requestOptions)
    //     .then((response) => response.text())
    //     .then(() => {
    //       this.$router.push({ name: "home" });
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    async edit() {
      const id = this.$route.params.id;
      this.$router.push({ name: "edit", params: { id: id } });
    },
    async delet() {
      const id = this.$route.params.id;
      const valid = confirm(
        "Are you sure that you want to delete this post-it"
      );
      if (valid) {
        const value = await this.deletePost(id);
        if (value) {
          const fresh = this.refreshlist();
          if (fresh) {
            this.$router.push({ name: "home" });
          }
        }
      }
      // var requestOptions = {
      //   method: "DELETE",
      //   redirect: "follow",
      // };
      // fetch("https://post-it.epi-bluelock.bj/notes/" + id, requestOptions)
      //   .then((response) => response.text())
      //   .then(() => {
      //     /* console.log(result)*/ this.$router.push({ name: "home" });
      //   })
      //   .catch((error) => console.log("error", error));
    },
    async retour() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style>
.bgcard {
  background-color: rgb(48, 154, 224);
  color: white;
}
</style>
