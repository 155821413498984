<template>
  <v-container>
    <p class="headline">Edit my Post-it</p>
    <v-row>
      <v-col>
        <v-sheet class="mx-auto" width="300">
          <v-form ref="form">
            <v-text-field
              v-model="title"
              :rules="titleRules"
              label="Title"
              required
            ></v-text-field>
            <v-textarea
              v-model="content"
              :rules="contentRules"
              dense
              height="300px"
              color="teal"
              required
            >
              <template v-slot:label>
                <div height="300px">Content</div>
              </template>
            </v-textarea>
            <div class="d-flex flex-column">
              <v-btn class="mt-4" color="success" block @click="update">
                Update
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    titleRules: [(v) => !!v || "Title is required"],
    contentRules: [(v) => !!v || "Content is required"],
  }),
  computed: {
    ...mapGetters(["curentPost"]),
    title: {
      get() {
        return this.curentPost ? this.curentPost.title : "";
      },
      set(value) {
        if (this.curentPost) {
          this.curentPost.title = value;
        }
      },
    },
    content: {
      get() {
        return this.curentPost ? this.curentPost.content.join(",") : "";
      },
      set(value) {
        if (this.curentPost) {
          this.curentPost.content = value.split(",");
        }
      },
    },
  },
  async mounted() {
    const id = this.$route.params.id;
    await this.getOneNote(id);
  },
  methods: {
    ...mapActions(["getOneNote", "updatePost", "refreshlist"]),
    async update() {
      const id = this.$route.params.id;
      await this.updatePost({ id, post: this.curentPost })
        .then(() => {
          const value = this.refreshlist();
          if (value) {
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<!-- <template>
  <v-container>
    <p class="headline">Edit my Post-it</p>
    <v-row>
      <v-col>
        <v-sheet class="mx-auto" width="300">
          <v-form ref="form">
            <v-text-field
              v-model="title"
              :rules="titleRules"
              label="Title"
              required
            ></v-text-field>
            <v-textarea
              v-model="content"
              :rules="contentRules"
              dense
              height="300px"
              color="teal"
              required
            >
              <template v-slot:label>
                <div height="300px">Content</div>
              </template>
            </v-textarea>
            <div class="d-flex flex-column">
              <v-btn class="mt-4" color="success" block @click="update">
                Update
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    requete: "",
    title: "",
    titleRules: [(v) => !!v || "Title is required"],
    content: "",
    contentRules: [(v) => !!v || "Content is required"],
  }),
  mounted: async function () {
    const id = this.$route.params.id;
    const response = await fetch("https://post-it.epi-bluelock.bj/notes/" + id);
    this.requete = await response.json();
    this.title = this.requete.title;
    this.content = this.requete.content.slice(",");
  },
  methods: {
    async update() {
      const id = this.$route.params.id;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const contdent = this.content.toString();
      var raw = JSON.stringify({
        title: this.title,
        content: [contdent],
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch("https://post-it.epi-bluelock.bj/notes/" + id, requestOptions)
        .then((response) => response.text())
        .then(() => {
          /*console.log(response);*/
          this.$router.push({ name: "home" });
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script> -->
