<template>
  <v-container>
    <p class="headline">Create a new Post-it</p>
    <v-row>
      <v-col>
        <h6 color="success" v-if="requete">{{ requete }}</h6>
        <v-sheet class="mx-auto" width="300">
          <v-form ref="form">
            <v-text-field
              v-model="title"
              :counter="10"
              :rules="titleRules"
              label="Title"
              required
            ></v-text-field>
            <v-textarea
              v-model="content"
              :rules="contentRules"
              dense
              height="300px"
              color="teal"
              required
            >
              <template v-slot:label>
                <div height="300px">Content</div>
              </template>
            </v-textarea>
            <div class="d-flex flex-column">
              <v-btn class="mt-4" color="success" block @click="validate">
                Validate
              </v-btn>
            </div>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    requete: "",
    title: "",
    titleRules: [(v) => !!v || "Title is required"],
    content: "",
    contentRules: [(v) => !!v || "Content is required"],
  }),
  computed: {
    ...mapState(["requete"]),
  },
  methods: {
    ...mapActions(["addPost"]),
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const postit = {
          title: this.title,
          content: [this.content],
        };
        this.addPost(postit)
          .then(() => {
            this.$router.push({ name: "home" });
          })
          .catch((error) => console.log("error", error));
        // var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // var raw = JSON.stringify({
        //   title: this.title,
        //   content: [this.content],
        // });
        // var requestOptions = {
        //   method: "POST",
        //   headers: myHeaders,
        //   body: raw,
        //   redirect: "follow",
        // };
        // fetch("https://post-it.epi-bluelock.bj/notes", requestOptions)
        //   .then((response) => response.text())
        //   .then(() => {
        //     this.$router.push({ name: "home" });
        //   })
        //   .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>
